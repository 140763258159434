<template>
  <div>
    <div :class="!invitationOpen ? `container` : `container open`">
      <div class="row justify-content-center">
        <div id="cover" class="col-sm-12 col-md-8 col-lg-6"></div>
        <div
          id="cover-overlay"
          class="col-sm-12 col-md-8 col-lg-6 d-flex justify-content-between flex-column text-light"
        >
          <div class="title-row text-center pt-5">
            <h4 class="mb-2"><span class="p-1 thwo">THE WEDDING OF</span></h4>
            <h1 class="title text-primary display-2">Arief & Suci</h1>
          </div>

          <div class="open-invitation text-center pb-5">
            <button
              class="btn btn-lg btn-primary mb-5"
              @click="invitationOpen = true"
            >
              <i class="feather icon-mail"></i> Buka Undangan
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="invitationOpen" class="invitation-content">
      <button
        class="btn btn-link btn-sm"
        id="close-invitation"
        @click="invitationOpen = false"
      >
        <i
          class="feather icon-x-circle font-large-1 rounded-circle bg-light"
        ></i>
      </button>
      <audio
        src="../assets/audio.mp3"
        autoplay
        loop
        style="display: none"
      ></audio>
      <div class="row justify-content-center mx-auto">
        <div class="col-sm-12 col-md-8 col-lg-6 px-0">
          <!-- save the date -->
          <div
            class="d-flex align-items-center invitation-parallax"
            id="savethedate"
          >
            <h1 class="w-100 text-center bg-primary text-light p-1 display-4">
              Save The Date <br />
              18.02.24
            </h1>
          </div>
          <!-- /save the date -->

          <!-- words -->
          <div class="d-flex-centered" id="words">
            <p class="text-primary text-center px-5">
              "Dan di antara tanda-tanda kebesarannya ialah diciptakanNya
              untukmu istri-istri dari jenismu sendiri, supaya kamu cenderung
              dan merasa tentram kepadanya, dan dijadikan-Nya di antaramu rasa
              kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar
              terdapat tanda-tanda bagi kaum yang berfikir"
            </p>
            <p class="text-light font-weight-bold mt-2">- AR-RUM 21-</p>
          </div>
          <!-- /words -->

          <!-- invitation-text -->
          <div class="d-flex-centered" id="invitation-text">
            <img
              src="../assets/images/flower.png"
              alt="flower"
              class="flower-tr"
            />
            <div class="px-5">
              <p>Assalamualaikum Warahmatullahi Wabarakatuh</p>
              <p>
                Tanpa mengurangi rasa hormat, kami mengundang Bapak/ Ibu/
                Saudara/ (i)
              </p>
              <p>untuk dapat hadir dalam acara pernikahan kami</p>
            </div>
            <img
              src="../assets/images/flower.png"
              alt="flower"
              class="flower-bl"
            />
          </div>
          <!-- /invitation-text -->

          <div id="couple-divider">
            <img
              src="../assets/images/flower-2.png"
              alt="flower-2"
              class="img-fluid"
            />
          </div>
          <!-- the bride -->
          <div class="row" id="the-bride">
            <div class="col-2 d-flex-centered">
              <h1 class="rotate-90 display-5 the-bride-text text-light">
                THE BRIDE
              </h1>
            </div>
            <div class="col-10 p-0" style="position: relative">
              <img
                src="https://api.nikahankami.click/storage/images/the-bride.jpg"
                alt="the-bride"
                class="img-fluid"
              />
              <div
                class="bridegroom-name"
                style="position: absolute; bottom: 5vh"
              >
                <h2 class="text-primary font-s">
                  Suci Kholifah Mentari Kasih, Amd.Farm
                </h2>
                <p class="text-dark mb-0">
                  Putri dari Bapak Muhammad Basyir & Ibu Sri Hasilawati
                </p>
              </div>
            </div>
          </div>
          <!-- /the bride -->

          <div id="couple-divider">
            <img
              src="../assets/images/flower-2.png"
              alt="flower-2"
              class="img-fluid"
            />
          </div>

          <!-- the groom -->
          <div class="row" id="the-groom">
            <div class="col-10 p-0">
              <img
                src="https://api.nikahankami.click/storage/images/the-groom.jpg"
                alt="the-bride"
                class="img-fluid"
              />
              <div
                class="bridegroom-name"
                style="position: absolute; bottom: 5vh; right: 0"
              >
                <h2 class="text-primary font-s">
                  Muhammad Arief Fadhillah, Amd.Kes
                </h2>
                <p class="text-dark mb-0">
                  Putra dari Bapak Abdul Hadi & Ibu Hj. Mas'im, S.Pd
                </p>
              </div>
            </div>
            <div class="col-2 d-flex-centered">
              <h1 class="rotate-90 display-5 the-groom-text text-light">
                THE GROOM
              </h1>
            </div>
          </div>
          <div id="couple-divider">
            <img
              src="../assets/images/flower-2.png"
              alt="flower-2"
              class="img-fluid flip"
            />
          </div>
          <!-- /the groom -->

          <!-- our day -->
          <div class="d-flex-centered pb-5" id="our-day">
            <div class="py-5 px-5 w-100">
              <div id="our-day-content" class="font-s pb-5 bg-light">
                <span class="badge p-2 mb-2 text-primary">
                  <div class="mb-2">
                    <i class="feather icon-calendar font-large-1"></i>
                  </div>
                  <h3 class="text-primary font-large-1">MINGGU</h3>
                  <h1 class="text-primary font-large-5">18</h1>
                  <h3 class="text-primary font-large-1">FEB 2024</h3>
                </span>
                <img
                  src="https://api.nikahankami.click/storage/images/the-love.jpg"
                  alt="love"
                  class="img-fluid mb-2"
                />
                <div class="mb-2">
                  <i class="feather icon-clock font-large-1"></i>
                </div>
                <div class="mb-2">
                  <h4 class="text-primary">Akad</h4>
                  <h1 class="text-primary font-large-1">09.00 WITA</h1>
                </div>
                <div class="mb-2">
                  <h4 class="text-primary">Resepsi</h4>
                  <h1 class="text-primary font-large-1">11.00 ~ 15.00 WITA</h1>
                </div>
              </div>
            </div>
          </div>
          <!-- /our day -->
          <div id="couple-divider">
            <img
              src="../assets/images/flower-2.png"
              alt="flower-2"
              class="img-fluid"
            />
          </div>
          <!-- embed-location -->
          <div class="d-flex bg-light text-primary text-center py-4">
            <div class="w-100 pt-2">
              <p class="font-large-1 font-s mb-2">
                <i class="feather icon-map-pin"></i> Tempat Acara
              </p>
              <p class="font-weight-bold mb-0">
                Jl. Kemakmuran No.2, Sungai Pinang Dalam,
              </p>
              <p class="font-weight-bold mb-3">
                Kec. SungaiPinang, Kota Samarinda, Kalimantan Timur 75117
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1605.9815576038607!2d117.16227232341409!3d-0.4810055240699564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2df67f5cbe267ef5%3A0x1f39372a982a97e4!2sDepartment%20of%20Manpower%20and%20Transmigration!5e0!3m2!1sen!2sid!4v1705744600921!5m2!1sen!2sid"
                width="100%"
                height="450"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <a
                href="https://maps.app.goo.gl/wqTeFPwNyju4vzs89"
                target="_blank"
                class="btn btn-link"
              >
                <i class="feather icon-send"></i> Buka map
              </a>
            </div>
          </div>
          <!-- /embed-location -->

          <!-- gift -->
          <!-- <div class="w-100 p-2 bg-primary">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title text-center text-primary">
                  <i class="feather icon-gift"></i> GIFT
                </h4>
              </div>
              <div class="card-body text-center">
                <h1 class="text-primary text-center">BRI</h1>
                <p class="text-primary">Suci Kholifah Mentari Kasih</p>
                <h1 class="bg-primary rounded text-light py-2">{{ no_rek }}</h1>
                <button @click="copyNoRek" class="btn btn-outline-primary mb-2">
                  <i class="feather icon-copy font-medium-5"></i> Copy Number
                </button>
                <p v-if="norek_copied" class="text-primary font-weight-bolder">
                  Number Copied.
                </p>
              </div>
            </div>
          </div> -->
          <!-- /gift -->

          <div id="couple-divider">
            <img
              src="../assets/images/flower-2.png"
              alt="flower-2"
              class="img-fluid"
            />
          </div>

          <!-- Gallery -->
          <div class="w-100 bg-primary pt-5 p-3">
            <p class="font-large-1 font-s text-center text-light">
              <i class="feather icon-image"></i> Galeri Kami
            </p>
            <div
              v-for="(item, index) in galleries"
              :key="index"
              class="card rounded-0"
            >
              <div class="card-body">
                <img :src="item.link" alt="img-galeri" class="img-fluid" />
              </div>
            </div>
          </div>
          <!-- /Gallery -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      invitationOpen: false,
      no_rek: "456601002256506",
      norek_copied: false,
      galleries: [],
    };
  },
  mounted() {
    this.fetchGalleries();
  },
  methods: {
    openInvitation() {
      alert("on progress");
    },
    copyNoRek() {
      navigator.clipboard.writeText(this.no_rek);
      this.norek_copied = true;
      setTimeout(() => {
        this.norek_copied = false;
      }, 2000);
    },
    fetchGalleries() {
      this.$http
        .get("https://api.nikahankami.click/api/galleries")
        .then((res) => {
          this.galleries = res.data;
        })
        .catch((err) => console.log(err.response));
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

.container {
  height: 100vh;
  position: relative;
}

.container.open {
  height: 0 !important;
  overflow: hidden !important;
  /* margin: 0 !important;
  padding: 0 !important; */
  transition: all 0.6s;
}

.d-flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#close-invitation {
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 10px;
  z-index: 9999;
}

#cover,
#cover-overlay {
  height: 100%;
  position: absolute;
}

#cover {
  background-image: url("https://api.nikahankami.click/storage/images/cover.jpg");
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
}

.invitation-parallax {
  width: 100%;
  height: 100vh;
}

#savethedate {
  background-image: url("https://api.nikahankami.click/storage/images/save-the-date.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-color: var(--primary);
}

#cover-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

#our-day {
  background-color: var(--primary);
}

#our-day-content {
  /* border-primary rounded-circle h-100 w-100 text-center p-2 */
  width: 100%;
  min-height: 100%;
  text-align: center;
  padding: 4rem 2rem 0;
  color: var(--primary) !important;
  border: 1px solid var(--primary);
  border-top-left-radius: 18rem !important;
  border-top-right-radius: 18rem !important;
  border-bottom-left-radius: 18rem !important;
  border-bottom-right-radius: 18rem !important;
}

.font-s,
.title,
.invitation-parallax h1 {
  font-family: "Dancing Script", cursive;
}
.thwo {
  background-color: rgba(255, 200, 200, 0.5);
  color: var(--light);
  font-weight: bold;
}
.invitation-content {
  width: 100%;
  height: 100vh;
  overflow-x: hidden !important;
}

#words {
  height: 100vh;
}
#words p {
  font-size: 18pt;
  line-height: 3rem;
}

#invitation-text {
  height: 60vh;
  background-color: var(--light);
  color: var(--primary);
  font-weight: bolder;
  position: relative;
}

#invitation-text p {
  font-size: 14pt;
  line-height: 2rem;
  text-align: center;
}

.flower-tr {
  position: absolute;
  margin-right: -20px;
  margin-top: -20px;
  width: 30%;
  right: 0;
  top: 0;
}

.flower-bl {
  position: absolute;
  margin-left: -20px;
  margin-bottom: -20px;
  width: 30%;
  bottom: 0;
  left: 0;
  transform: rotate(180deg);
}

.flip {
  transform: rotate(180deg);
}

#the-bride .the-bride-text,
#the-groom .the-groom-text {
  width: max-content;
}
#the-groom,
#the-bride {
  height: max-content;
  background-color: var(--primary);
  margin: 0 auto;
}
#couple-divider {
  width: 100%;
  text-align: center;
  height: 5vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: var(--light);
}
#couple-divider img {
  z-index: 99;
}

.bridegroom-name {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem 2rem;
}
</style>
